import * as React from 'react';

import Layout from '../components/layout';

const WhatToBring = () => {
  var pageTitle = 'What to Bring';

  return (
    <Layout
      slug="what-to-bring"
      title={pageTitle}
      description="Learn more about how to prepare your pet for boarding!"
    >
      <h1>{pageTitle}</h1>
      <h2>Dogs and Cats</h2>
      <ul>
        <li>Up to date copy of vaccine records</li>
        <li>
          Food - please provide your pet's usual diet. We also ask that you
          provide extra in case you have a change of plans, inclement weather or
          are delayed from picking up your pet for any other reason.
        </li>
      </ul>
      <p>
        We are able to provide everything else for your pet however you are very
        welcome to bring anything that you feel will help them settle in. This
        includes a favourite blanket, toys, treats etc. For cats, we can supply
        a litter box as well as cat litter; however, if your cat has a strong
        preference to a particular litter or litter box feel free to bring your
        own. We do not reccommend bringing your own bowls unless your pet has a
        specific preference. We even have slow feeder bowls available to pets
        that need that stimulation or help in slowing down their meal!
      </p>
      <p>
        <b>Note:</b> You are welcome to bring your pet's own bedding and toys;
        however, please keep in mind that we are an indoor/outdoor facility.
        While we have a washer and dryer on site depending on the circumstances
        we are not always able to wash items before pick up.
      </p>

      <h2>Small Animals</h2>
      <ul>
        <li>
          Own food/treats - please provide extra in case you have a change of
          plans, inclement weather or are delay from picking up your pet for any
          other reason.
        </li>
        <li>Own housing</li>
        <li>Bedding</li>
      </ul>
      <h2 className="h1">How To Prepare</h2>
      <p>
        We understand that leaving your pet, especially for the first time, can
        be an emotional experience. Here are some tips to help you feel more
        comfortable as well as to help your pet settle in!
      </p>

      <ol>
        <li>
          If you are concerned about how your pet will react to their first time
          boarding we recommend coming for a tour of our facility with your pet
          or signing them up for a short boarding trial. For social dogs
          spending a day or two at doggie daycare can make boarding an exciting
          experience to look forward to.
        </li>
        <li>
          When dropping off your pet we recommend saying a quick goodbye,
          similar to if you were leaving for work. Since pets can be very
          perceptive to our emotions this helps to eliminate the chance for them
          to pick up on any anxiety you may be feeling. Remember, we are always
          willing to give updates on your pet as a big part of our job is making
          sure you feel comfortable leaving your pets with us!
        </li>
        <li>
          Make it an exciting experience for your pet! Tell them how much fun
          they are going to have during their time with us. This helps to get
          them excited for their visit and aids in creating a stress free and
          enjoyable stay. For many of dogs coming to the kennel is like going to
          summer camp and they can't wait to get here!
        </li>
        <li>
          For your pets first time boarding it can be comforting to have
          something that smells like home. These can be things such as toys and
          bedding or even an article of your own clothing.
        </li>
      </ol>

      <h2 className="h1">Returning Home</h2>
      <p>
        Due to the increase mental stimulation, and in some cases physical
        exercise, it is very common for dogs to be tired upon returning home. It
        is perfectly normal for a dog to sleep more for the first few days after
        leaving our care. Sometimes, however, energy creates energy. Though dogs
        get many opportunities to rest while in our care, they may still be
        excited from their time with us as well as your return home. It is also
        normal for them to be extra excited upon returning home, however, they
        should settle down soon.
      </p>
      <p>
        While pets are in our care they always have access to fresh water
        however, sometimes they are having so much fun that they forget to take
        the time to drink. This is more common in dogs who are in our daycare
        program however it can occur in boarding dogs as well. Many dogs eat
        more while in our care as a result of increased activity levels and
        feeding amounts may need to be adjusted while here. It is not uncommon
        for them to be hungry upon returning home.
      </p>
    </Layout>
  );
};

export default WhatToBring;
